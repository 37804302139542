import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import UserInfo from "../components/UserInfo/UserInfo"
import SocialLinks from "../components/ShareArticleLinks"
import SEO from "../components/SEO/SEO"
import config from "../../data/SiteConfig"
import Text from "../styles/typography"
import { css, useTheme } from "@emotion/react"
import {
  breakpoints,
  colorPrimatives,
  fontFamilies,
  fontSizes,
  mediaQueries,
} from "../styles/theme"
import moment from "moment"
import parse from "html-react-parser"
import options from "../html-react-parsing-options"
import TagList from "../components/Taglist"
import ArticleImage from "../components/ArticleImage"
import ArticleFigure from "../components/ArticleFigure"
import ArticleContainer from "../components/ArticleContainer"
import ArticleBody from "../components/ArticleBody"
import Basmala from "../components/Basmala"

export default function PostTemplate({ data, pageContext }) {
  const theme = useTheme()

  const { slug } = pageContext
  const postNode = data.markdownRemark
  const post = postNode.frontmatter
  if (!post.id) {
    post.id = slug
  }

  return (
    <>
      <Helmet>
        <title>{`${post.title} | ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postPath={slug} postNode={postNode} postSEO />
      <ArticleContainer>
        <Text
          css={css`
            padding-top: 32px;
            padding-bottom: 16px;
            font-family: ${fontFamilies.tajawal};
            color: ${theme.color.quote};
          `}
        >
          {moment(post.date).format("LL")}
        </Text>
        <ArticleBody>
          <Text
            el="h1"
            css={css`
              margin: auto;
            `}
          >
            {post.title}
          </Text>

          {post.cover && (
            <ArticleFigure>
              <ArticleImage src={post.cover} />
              {post.coverCaption && (
                <Text el="figcaption">{post.coverCaption}</Text>
              )}
            </ArticleFigure>
          )}
          <Basmala />
          {postNode.html && parse(postNode.html, options())}
        </ArticleBody>
        <TagList tags={post.tags} />
        <hr />
        <SocialLinks postPath={slug} postNode={postNode} />
        <UserInfo config={config} />
      </ArticleContainer>
    </>
  )
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: { regex: "/content/articles/" }
    ) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        coverCaption
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`
