import { css } from "@emotion/react"
import React from "react"
import {
  FacebookShareButton,
  FacebookShareCount,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditShareButton,
  RedditShareCount,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import urljoin from "url-join"
import config from "../../data/SiteConfig"
import { fontFamilies } from "../styles/theme"
import Text from "../styles/typography"

function SocialLinks({ postNode, postPath, mobile }) {
  const post = postNode.frontmatter
  const url = urljoin(config.siteUrl, config.pathPrefix, postPath)
  const iconSize = mobile ? 36 : 48
  const filter = count => (count > 0 ? count : "")
  const renderShareCount = count => (
    <div className="share-count">{filter(count)}</div>
  )

  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 32px;

        svg {
          width: 32px;
          height: 32px;
          margin: 8px;
        }

        > div {
          margin: 16px;
          cursor: pointer;
        }

        .share-count {
          text-align: center;
        }
      `}
    >
      <Text
        css={css`
          font-family: ${fontFamilies.tajawal};
          margin: 16px;
          width: 100%;
          text-align: center;
        `}
      >
        Share this article on social media:
      </Text>
      <RedditShareButton url={url} title={post.title}>
        <RedditIcon round size={iconSize} />
        <RedditShareCount url={url}>
          {count => renderShareCount(count)}
        </RedditShareCount>
      </RedditShareButton>
      <TwitterShareButton url={url} title={post.title}>
        <TwitterIcon round size={iconSize} />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={postNode.excerpt}>
        <FacebookIcon round size={iconSize} />
        <FacebookShareCount url={url}>
          {count => renderShareCount(count)}
        </FacebookShareCount>
      </FacebookShareButton>
      <LinkedinShareButton
        url={url}
        title={post.title}
        description={postNode.excerpt}
      >
        <LinkedinIcon round size={iconSize} />
      </LinkedinShareButton>
      <TelegramShareButton url={url}>
        <TelegramIcon round size={iconSize} />
      </TelegramShareButton>
      <WhatsappShareButton
        url={url}
        title={post.title}
        separator={`
`}
      >
        <WhatsappIcon round size={iconSize} />
      </WhatsappShareButton>
    </section>
  )
}

export default SocialLinks
